import { getApi, postApi, getApiWithoutToken, postApiWithoutToken, deleteApi, getApiLocal, postApiMultipart } from "./api-interface";
import { apiBaseUrl } from "./Baseurl"

export const userLogin = (data) => {
   return postApi(`/api/user/login`, data)
}

export const categoryList = () => {
   return getApi(`/api/category/list`)
}

export const tagList = () => {
   return getApi(`/api/tag/list`)
}

export const categoryWithTagList = () => {
   return getApi(`/api/category/categoryWithTagList`)
}

export const assignedTagList = (data) => {
   return postApi(`/api/tag/assignedTagList`, data)
}

export const deleteVideo = (data) => {
   return postApi(`/api/video/deleteVideo`,data)
}

export const deleteDraftVideo = (data) => {
   return postApi(`/api/video/deleteDraftVideo`,data)
}
export const multipleDeleteVideo = (data) => {
   return postApi(`/api/video/multipleDeleteVideo`,data)
}

export const getEditVideoData = (data) => {
   return postApi(`/api/video/getEditVideoData`,data)
}

export const getDraftVideoData = (data) => {
   return postApi(`/api/video/getDraftVideoData`,data)
}

export const submitVideo = (data) => {
   return postApiMultipart(`/api/video/uploadVideo`,data)
}
export const saveDraftEditVideo = (data) => {
   return postApi(`/api/video/saveDraftEditVideo`,data)
}

export const submitMultipleVideo = (data) => {
   return postApiMultipart(`/api/video/uploadMultipleVideo`,data)
}

export const submitEditVideo = (id,data) => {
   return postApiMultipart(`/api/video/uploadEditVideo/${id}`,data)
}

export const getVideoDetail = (data) => {
     return postApi(`/api/video/getVideoDetail`,data)
}

export const increaseViewCount = (data) => {
     return postApi(`/api/video/increaseViewCount/${data}`)
}
export const relatedVideos = (data) => {
     return postApi(`/api/video/relatedVideos/${data}`)
}

export const getVideosList = (data) => {
     return postApi("/api/video/allVideosList", data)
}

export const draftVideosList = (data) => {
     return postApi("/api/video/allDraftVideosList", data)
}

export const industryWithTagList = (data) => {
     return postApi("/api/category/industryWithTagList", data)
}
export const industryTagVideoCount = () => {
     return getApi("/api/category/industryTagVideoCount")
}

export const createNewCategory = (data) => {
   return postApi("/api/category/createNewCategory", data)
}

export const editSaveCategoryName = (data) => {
   return postApi("/api/category/editSaveCategoryName", data)
}

export const createNewTag = (data) => {
   return postApi("/api/tag/createNewTag", data)
}
export const updateCategoryTagName = (data) => {
   return postApi("/api/tag/updateTagName", data)
}

export const CreateUser = (data) => {
   return postApi("/api/user/create", data)
}
export const changePassword = (data) => {
   return postApi("/api/user/changePassword", data)
}