import React, { useEffect, useState } from 'react';
import HomeBannner from '../banner/HomeBannner'
import Filter from '../filter/Filter'
import VideoThumbnailCard from '../VideoThumbnailCard/VideoThumbnailCard';
import pharmaceutical from '../../assets/images/genres/pharmaceutical.png'
import automotive from '../../assets/images/genres/automotive.png'
import paintchemicals from '../../assets/images/genres/paintchemicals.png'
import automation from '../../assets/images/genres/automation.png'
import oilgas from '../../assets/images/genres/oilgas.png'
import energy from '../../assets/images/genres/energy.png'
import construction from '../../assets/images/genres/construction.png'
import renewable from '../../assets/images/genres/renewable.png'
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import FormGroup from '@mui/material/FormGroup';
import ReactPaginate from "react-paginate";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { categoryWithTagList, getVideosList, industryWithTagList } from '../../utils/api-Request';
import MostViewed from "../mostViewed/MostViewed"
import Industry from "../Industry/Industry"
import LatestVideo from "../LatestVideo/LatestVideo"
import FilterVideo from "../FilterVideo/FilterVideo"

const Home = (props) => {
  const [latestVideo, setLatestVideo] = useState([]);
  const [filterVideo, setFilterVideo] = useState([]);
  const [searchNameTag, setSearchNameTag] = useState([]);
  const [catTagList, setCatTagList] = useState([]);
  const [filter, setFilter] = useState([]);
  const [showLatestVideo, setShowLatestVideo] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [changeBanner, setChangeBanner] = useState("false")
  const [propsDataForHeader, setPropsDataForHeader] = useState({});
  const[searchHeaderValue,setSearchHeaderValue] = useState("");

  const _categoryWithTagList = async () => {
    try {
      let res = await categoryWithTagList();
      if (res?.data?.list) {
        setCatTagList(res?.data?.list)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    _categoryWithTagList();
   }, []);


  const _filterVideoList = async () => {
    console.log("filter");
    console.log(filter);
    try {
      let data = {
        filter: filter,
        search:  searchHeaderValue,
        pageLimit: 99999,
        page: 1,
        order: [["createdAt", "DESC"]],
      };

    let res = await getVideosList(data);

      if (changeBanner == "true") {
        let resData = res?.data?.data;
        const filteredData = resData.filter(item => item.makeBanner === "true");
        if (filteredData) {
          setPropsDataForHeader(filteredData[0])
        }
      }
      
      setFilterVideo(res?.data?.data);
      setTotalPages(res?.data?.totalPages)

    } catch (error) {

    }
  }

  const _allVideoList = async () => {
    try {

      let data = {
        search: "",
        pageLimit: 99999,
        page: 1,
        order: [["createdAt", "DESC"]],
      };

      let res = await getVideosList(data);
      setFilterVideo(res?.data?.data);
      setShowLatestVideo(false);

    } catch (error) {

    }
  }



  useEffect(() => {

    if (filter.length || searchHeaderValue.length) {
      setShowLatestVideo(false);
      _filterVideoList();
    }else {
      setShowLatestVideo(true);
    }

  }, [filter , searchHeaderValue]);


  const industryVideoList = async (o) => {
    try {
      setChangeBanner("true");
      delete o. count
      setPropsDataForHeader({});
     
      if (!filter.includes(o)) {
          setFilter(prevSearch => [...prevSearch, o]);
        } else {
          setFilter(prevSearch => prevSearch.filter(item => item !== o));
        }

        if (searchNameTag.some((object) => object.id == o.id)) {
          setSearchNameTag( searchNameTag => searchNameTag.filter((object) => object.id !== o.id) );
        } else {
          setSearchNameTag([...searchNameTag, o]);
        }

      } catch (error) {

    }
  }

  useEffect(()=> {
      setSearchHeaderValue(props.searchHeaderValue);
      // setChangeBanner("false");
      // setPropsDataForHeader({})
      // setFilter([])
      // setSearchNameTag([])
  }, [props.searchHeaderValue]);

   // start from here

   const removeSearchTagName = (e) => {
      setFilter(prevSearch => prevSearch.filter(item => item.id !== e.id));
      setSearchNameTag(searchNameTag=> searchNameTag.filter((object) => object.id !== e.id) );
    }

    const clearAllFilter = () => {
      setChangeBanner("false");
      setPropsDataForHeader({})
      setFilter([])
      setSearchNameTag([])
    }

    const handleFilterChange = async (e,n,o) => {
      try {

        setChangeBanner("false");
        setPropsDataForHeader({});
  
       if (!filter.includes(o)) {
          setFilter(prevSearch => [...prevSearch, o]);
        } else {
          setFilter(prevSearch => prevSearch.filter(item => item !== o));
        }
  
  
       if (searchNameTag.some((object) => object.id == o.id)) {
        setSearchNameTag( searchNameTag => searchNameTag.filter((object) => object.id !== o.id) );
       } else {
        setSearchNameTag([...searchNameTag, o]);
       }
  
      } catch (error) {
  
      }
    }

   const cancelAllVideosList=()=>{
    setShowLatestVideo(true);
    clearAllFilter()
   }

  return (
    <>
      <HomeBannner changeBanner={changeBanner} _propsDataForHeader={propsDataForHeader} />

      <section className='industriessection'>
        <div className="container">
          <div className="row">

            <div className="col-md-3 px-2">
              <h3 className='mfilter'>
                  <span>
                    <ul>
                     
                     {searchNameTag.map((e,i)=>{ 
                      return (
                          <li key={i}>
                              <span className='filterin'>{e.name}<span onClick={()=>removeSearchTagName(e)} > X</span></span>
                          </li>
                      ) })
                    }      

                    </ul>
                  </span>
                  <span className='clearall' onClick={clearAllFilter}>Clear All</span>
               </h3>
              <Accordion className='position-sticky top-5 cartScrollBar'>
                {catTagList.map((item, index) => {
                  return (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.name}</Accordion.Header>
                      <Accordion.Body>
                        <div className='filterbx'>
                          <FormGroup>
                            {item.tags.map((e, i) => {
                              return (
                                <FormControlLabel
                                  key={i}
                                  checked={filter.includes(e)}
                                  control={<Checkbox />}
                                  label={e.name}
                                  onChange={()=>handleFilterChange(e.id,e.name,e)}
                                />)
                            })}
                          </FormGroup>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  )
                })}
              </Accordion>
            </div>

            {  showLatestVideo ?

                <div className="col-md-9 px-2">
                <h5 className='d-flex align-items-center justify-content-between'>Latest Videos <button onClick={_allVideoList} className='viewall mt-2'>View All</button></h5>
                 <LatestVideo />
                <hr />
                  <Industry industryAll={(e) => industryVideoList(e)} />
                </div>

                :
                <div className="col-md-9 px-2">
                   <h5 className='d-flex align-items-center justify-content-between'> {filter.length > 0 || searchHeaderValue.length > 0? "Filter Videos" :"All Videos"} <button onClick={cancelAllVideosList} className='viewall mt-2'>Cancel</button></h5>
                   <FilterVideo data={filterVideo} />
               </div>
                 
            }

          </div>
        </div>
      </section>

      {showLatestVideo ?
        <MostViewed />
        :
        null
      }

    </>
  )
}

export default Home