import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player'
import VideoThumbnailCard from '../VideoThumbnailCard/VideoThumbnailCard';
import { getVideoDetail, increaseViewCount, relatedVideos } from "../../utils/api-Request";
import YouTubeIcon from '@mui/icons-material/YouTube';
import { videoPath } from "../../server"

const DetailPage = () => {

    const [videoData, setVideoData] = useState([]);
    const [relatedVideo, setRelatedVideo] = useState([]);
    const [lastIndexId, setLastIndexId] = useState("");
    const lastId = window.location.href.split("/");
    let lastIndexNumber = lastId[lastId.length - 1];
    const _getVideoDetails = async (id) => {
        try {
            let data = {
                id
            }
            let res = await getVideoDetail(data);
            setVideoData(res.data.data)

        } catch (error) {

        }
    }

    const _increaseViewCount = async (id) => {
        try {
            let res = await increaseViewCount(id)
        } catch (error) {

        }
    }

    const _getRelatedVideos = async (id) => {
        try {
            let res = await relatedVideos(id);
            if (res) {
                setRelatedVideo(res?.data?.data)
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        setLastIndexId(lastIndexNumber);
        _getVideoDetails(lastIndexNumber);
        _increaseViewCount(lastIndexNumber);
        _getRelatedVideos(lastIndexNumber);

    }, [lastIndexNumber]);


    return (
        <>
            <section className="detailpage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div>
                                <ReactPlayer
                                    className='react-player fixed-bottom'
                                    url={`${videoPath}${videoData?.video_path}`}
                                    width='100%'
                                    height='100%'
                                    controls={true}
                                    playing={true}
                                    config={{
                                        file: {
                                            attributes: {
                                                controlsList: 'nodownload',
                                                width: '100%',
                                                height: '100%',
                                            },
                                            tracks: [
                                                {
                                                    kind: 'captions',
                                                    //   src: {`${videoPath}${videoData?.video_path}`},
                                                    srcLang: 'en',
                                                    default: true,
                                                },
                                            ],
                                        },
                                    }}
                                />
                            </div>
                            <h4 className='d-flex mt-2'>{videoData?.title}  <a href={videoData?.youtubeURL} target="_blank" className='ms-3'><YouTubeIcon /> </a>      </h4>
                            <p>{videoData?.description}</p>
                        </div>
                        <div className="col-md-4">
                            <div className="siggestedvideo ps-4">
                                <h1 className='mb-3'>Related Videos</h1>
                                <ul className="relatedVideo">
                                    <li>
                                        <div>
                                            {relatedVideo.map((e, i) => {
                                                return (
                                                    <div  key={i}>
                                                        <VideoThumbnailCard data={e} />
                                                    </div>
                                                )
                                            })
                                            }
                                            {/* <VideoThumbnailCard title="12424424" createdAt="2023-04-28 08:28:17" description="345454lk6j45lk6j4l5kj645lk64l56"/> */}
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DetailPage