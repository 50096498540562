import * as yup from 'yup';

export const validationSchema_videoUpload = yup.object({
    title: yup
        .string()
        .required('Enter organization name'),
    description: yup
        .string()
        .required('Enter organization name'),
    indTag:yup.array().of(
        yup.object()
      ).min(1, 'Please select at least one industry'),
    prdTag:yup.array().of(
        yup.object()
      ).min(1, 'Please select at least one Product'),
    techTag:yup.array().of(
        yup.object()
      ).min(1, 'Please select at least one Technology'),
    useCaseTag:yup.array().of(
        yup.object()
      ).min(1, 'Please select at least one Use Case'),
    clientTag:yup.array().of(
        yup.object()
      ).min(1, 'Please select at least one Client Tag'),
});

export const validationSchema_userCreate = yup.object({
  name: yup
    .string()
    .required('Enter user name'),
  email: yup
    .string()
    .email('Enter a valid email address')
    .required('Enter user email'),
  phone: yup
    .string()
    .min(10, 'Enter 10 digit')
    .max(10, 'Enter 10 digit')
    .required('Enter user phone'),
  password: yup
    .string()
    .min(5, 'Password must be at least 5 characters')
    .required('Enter user password'),
});

export const validationSchema_changePassword = yup.object({
  oldPassword: yup
    .string()
    .min(5, 'Password must be at least 5 characters')
    .required('Enter user password'),
    newPassword: yup
    .string()
    .min(5, 'Password must be at least 5 characters')
    .required('Enter user password'),
});


