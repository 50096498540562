import React, { useEffect,useState } from 'react';
import HomeBannner from '../banner/HomeBannner'
import Filter from '../filter/Filter'
import VideoThumbnailCard from '../VideoThumbnailCard/VideoThumbnailCard';
import pharmaceutical from '../../assets/images/genres/pharmaceutical.png'
import automotive from '../../assets/images/genres/automotive.png'
import paintchemicals from '../../assets/images/genres/paintchemicals.png'
import automation from '../../assets/images/genres/automation.png'
import oilgas from '../../assets/images/genres/oilgas.png'
import energy from '../../assets/images/genres/energy.png'
import construction from '../../assets/images/genres/construction.png'
import renewable from '../../assets/images/genres/renewable.png'
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import FormGroup from '@mui/material/FormGroup';
import ReactPaginate from "react-paginate";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { categoryWithTagList, getVideosList} from '../../utils/api-Request';
import MostViewed from "../mostViewed/MostViewed"
import Industry from "../Industry/Industry"

const FilterVideo=(props)=> {

  const [filterVideoList,setFilterVideoList] = useState({});

  useEffect(()=>{
      setFilterVideoList(props?.data);
      },[props]);



  return (
    <>
    <section className='industriessection pt-0'>
            <div className="container">
                <div className="row">
                        <div className='videolistthumb'>
                            <div className="row">
                   
                                    {filterVideoList.length > 0 ?
                                        filterVideoList?.map((e, i) => {
                                            return (
                                            <div className="col-md-4">
                                                <VideoThumbnailCard data={e} key={i} />
                                            </div>
                                            )
                                        })
                                        :
                                    <p> No Result found. </p>
                                    }

                            </div>
                        </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default FilterVideo