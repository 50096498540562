import React, {useEffect } from 'react'
import Routers from './routers'
import { useLocation } from 'react-router-dom';

const DisableRightClickAndF12 = ({ children }) => {
  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 123) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return <div>{children}</div>;
};

function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
     {/* <DisableRightClickAndF12> */}
           <Routers/>
      {/* </DisableRightClickAndF12> */}
    </>
  )
}

export default App