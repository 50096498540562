import { apiBaseUrl } from "./Baseurl";
import axios from 'axios'


export const postApiWithoutToken = (path, data) => {
  return axios.post(apiBaseUrl + path, data, {
    headers: {
      "Content-Type": "application/json"
    }
  });
};



export const postApi = async(path, data) => {
    const token = localStorage.getItem("jwt");
    return axios.post(apiBaseUrl + path, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    })
  };
export const postApiMultipart = async(path, formdata) => {
    const token = localStorage.getItem("jwt");
    return await axios.post(apiBaseUrl + path, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`
      },
    });
     
  };

  export const getApiWithoutToken = (path) => {
    return axios.get(apiBaseUrl + path, {
      headers: {
        "Content-Type": "application/json"
      }
    })
  };
  
  export const getApi = (path, data) => {
    try {
        return axios.get(apiBaseUrl + path, {
            headers: {
              "Content-Type": "application/json",
              // "Authorization": `Bearer ${token}`
            }
          })    
    } catch (error) {
        console.log(error)
    }
    // const token = localStorage.getItem("jwt");
    
  };

export const putApiWithoutToken = (path, data) => {
  return axios.put(apiBaseUrl + path, data, {
    headers: {
      "Content-Type": "application/json"
    }
  })
};

export const putApi = (path, data) => {
  const token = localStorage.getItem("jwt");

  return axios.put(apiBaseUrl + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  })
};

export const fileUpload = (path, data) => {
  const token = localStorage.getItem("jwt");

  return axios.post(apiBaseUrl + path, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`
    }
  })
};


export const deleteApi = (path, data) => {
  const token = localStorage.getItem("jwt");

  return axios.delete(apiBaseUrl + path, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    data
  });
};


export const getApiLocal = (path, data) => {
  const token = localStorage.getItem("jwt");

  return axios.get(path, {
    headers: {
      "Content-Type": "application/json", 
    }
  })
};