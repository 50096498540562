import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import {
  TextField,
} from "@mui/material";
import { CreateUser } from '../../utils/api-Request';
import { validationSchema_userCreate} from "./validation";

const VideoUploader = () => {

    const [userCreateMsg, setUserCreateMsg] = useState({});
    const [error, setError] = useState(null);
    

    const formik = useFormik({
    initialValues: {
        name:"",
        email:"",
        password:"",
        phone:"",
    },
    validationSchema: validationSchema_userCreate,

    onSubmit: async (values) => {
        try {
            setError(null);
            setUserCreateMsg({});

            let resData = await CreateUser(values);

            if(resData.data.status == 0){
                setUserCreateMsg({
                    status:0,
                    msg:"Something Went Wrong."
                });
                setTimeout(()=>{
                    setUserCreateMsg({});
                    formik.resetForm();
                },1500)

            }else{
                setUserCreateMsg({
                    status:1,
                    msg:"User Created successfully."
                });

                setTimeout(()=>{
                    setUserCreateMsg({});
                    window.location.href = "/dashboard"
                    formik.resetForm();
                },3000)
            }

        } catch (error) {
            console.log(error)
            setError(error?.response?.data?.msg || "Something went wrong.")
        } finally {
                setTimeout(()=>{
                    setUserCreateMsg({});
                    setError(null);
                },3000)
        }
    }
       });

    const handleChangeInput = (event) => {
        formik.setFieldValue(event.target.name, event.target.value)
    }

    useEffect(()=>{
        setUserCreateMsg({})
    },[])

  return (
    <>
        
        <main className='maind'>
             <div className="upoadvideo">
                <div className="container-fluid">
                   <form onSubmit={formik.handleSubmit}>
                      <div className="row createUser">
                         <h2>Create User</h2>
                          <div className="col-6">
                                <TextField id="name" name="name" label="User Name" type="text" value={formik.values.name} onChange={handleChangeInput} error={formik.errors.name && formik.touched.name} fullWidth variant="outlined" />
                                      {formik.errors.name && formik.touched.name && <span className='text-danger'>{formik.errors.name}</span>}
                          </div>
                          <div className="col-6">
                                <TextField id="email" name="email" label="User Email" type="text" value={formik.values.email} onChange={handleChangeInput} error={formik.errors.email && formik.touched.email} fullWidth variant="outlined" />
                                      {formik.errors.email && formik.touched.email && <span className='text-danger'>{formik.errors.email}</span>}
                          </div>
                          <div className="col-6">
                                <TextField id="phone" name="phone" label="User phone" type="text" value={formik.values.phone} onChange={handleChangeInput} error={formik.errors.phone && formik.touched.phone} fullWidth variant="outlined" />
                                      {formik.errors.phone && formik.touched.phone && <span className='text-danger'>{formik.errors.phone}</span>}
                          </div>
                          <div className="col-6">
                                <TextField id="password" name="password" label="User Password" type="text" value={formik.values.password} onChange={handleChangeInput} error={formik.errors.password && formik.touched.password} fullWidth variant="outlined" />
                                      {formik.errors.password && formik.touched.password && <span className='text-danger'>{formik.errors.password}</span>}
                          </div>

                          <div className="col-12">
                               <button type="submit" className='btn btn-danger' > Save </button>
                              {userCreateMsg.length > 0 &&
                                  userCreateMsg.status == 0 ?
                                  <p className='text-danger wrongPassword' >{userCreateMsg.msg}</p>
                                   :
                                  <p className='text-success rightPassword' >{userCreateMsg.msg}</p>
                               }

                               {error &&
                                <p className='text-danger wrongPassword' >{error}</p>
                               }

                          </div>
                          
                      </div>
                    </form>
                </div>
             </div>
	    </main>

    </>
  )
}

export default VideoUploader