import React from 'react'
import { Link, NavLink } from 'react-router-dom';


function LeftBar() {
  return (
    <>
         <nav id="sidebar-nav">
            <ul id="vertical-sidebar-nav" className="sf-menu sf-js-enabled sf-arrows" style={{touchAction: 'pan-y'}}>
              <li className="normal-item-pro current-menu-item">
                <NavLink className={(navData) => (navData.isActive ? 'active' : '')} to="/video-uploader">
              <span className="icon-Upload"></span>
                  Upload
                </NavLink>
              </li>
              {/* <li className="normal-item-pro">
                <NavLink to="/edit-video-uploader">
                    <span className="icon-Edit"></span>
                    Edit
                </NavLink>
              </li> */}
              <li className="normal-item-pro">
                <NavLink to="/video-list" >
                    <span className="icon-File-Video"></span>
                  Video List
                </NavLink>
              </li>
              <li className="normal-item-pro">
                <NavLink to="/bulk-upload" >
                    <span className="icon-Upload"></span>
                 Draft upload
                </NavLink>
              </li>
              <li className="normal-item-pro">
                <NavLink to="/draft-list" >
                  <span className="icon-File-Video"></span>
                   Draft List
                </NavLink>
              </li>
            </ul>
            <div className="clearfix"></div>
        </nav>
    </>
  )
}

export default LeftBar