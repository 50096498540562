import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

function Filter2() {
      const [open, setOpen] = useState(false);
  return (
    <>
        <span className='opfilter' onClick={() => setOpen(!open)} aria-expanded={open}>
            <i className="fa fa-filter"></i> <span style={{fontSize:'17px', fontWeight:'600'}}>Filter</span>
        </span>
        <Collapse className='filterfrt' in={open}>
            <div className='row'>
                <div className="col-md-3">
                    <h3 className='mb-2'>Uploaded Time</h3>
                    <ul>
                        <li>Last Hour</li>
                        <li>Today</li>
                        <li>This Week</li>
                        <li>This Month</li>
                        <li>This Year</li>
                    </ul>
                </div>
                <div className="col-md-3">
                    <h3 className='mb-2'>Features</h3>
                    <ul>
                        <li>Normal Video</li>
                        <li>360<sup>0</sup> Video</li>
                        <li>3D Video</li>
                    </ul>
                </div>
                <div className="col-md-3">
                    <h3 className='mb-2'>Duration</h3>
                    <ul>
                        <li>Under 4 minutes</li>
                        <li>4 - 20 minutes</li>
                        <li>Over 20 minutes</li>
                    </ul>
                </div>
                 <div className="col-md-3">
                    <h3 className='mb-2'>Sort By</h3>
                    <ul>
                        <li>Relevance</li>
                        <li>Upload Date</li>
                        <li>View Count</li>
                    </ul>
                </div>
            </div>
        </Collapse>
    </>
  )
}

export default Filter2