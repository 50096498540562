import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import LeftBar from './LeftBar';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from "react-hook-form";
import { categoryWithTagList, tagList, categoryList, assignedTagList, submitMultipleVideo } from '../../utils/api-Request';
import Select from 'react-select';
import ReactPlayer from 'react-player'

const BulkUpload = () => {
    const fileInputRef = useRef(null);
    const [loaderHide, setLoaderHide] = useState(false);
    const [spinnerText, setSpinnerText] = useState("");
    const [videoUrls, setVideoUrls] = useState([]);
    const [uploadVideoError, setUploadVideoError] = useState("");

    useEffect(()=>{
      setLoaderHide();
      setSpinnerText("");
    },[])

    const onSubmit_UploadVideoData = async(values)=>{
      try {
              setLoaderHide(true);
              setSpinnerText("Uploading.....")
              const formData = new FormData();
               for (let i = 0; i < videoUrls.length; i++) {
                   formData.append('file', videoUrls[i].data);
                   formData.append('thumbnail', videoUrls[i].thumbnail);
               }
               setLoaderHide(true)
               let res= await submitMultipleVideo(formData);
               
               if(res?.data){
                setLoaderHide(false);
                setSpinnerText("");
                window.location.reload();
                }

      } catch (error) {
        console.log(error);
      }
     }

    const video_upload = async(e) =>{
      try {
        setLoaderHide(true);
        setSpinnerText("Processing.....")
        let forLocalFileURL = [];
       
        const fileInput = document.querySelector('.default-file-input');
        const file = fileInput.files;
        const files = Array.from(file);
        

        for (const f of files) {
        ///////////////////////////////////////////
                let uploadFileName = f.name.split('.').slice(0, -1).join('.');
                const videoElement = document.createElement('video');
                videoElement.src = URL.createObjectURL(f);

                const loadedMetadataPromise = new Promise((resolve) => {
                    videoElement.addEventListener('loadedmetadata', () => {
                        videoElement.currentTime = 2; // take thumbnail video 2 seconds
                        resolve();
                    });
                   });
                
                await loadedMetadataPromise;
                await new Promise((resolve) => {
                    
                      videoElement.addEventListener('seeked', () => {
                      const canvas = document.createElement('canvas');
                      canvas.width = videoElement.videoWidth;
                      canvas.height = videoElement.videoHeight;
                      const ctx = canvas.getContext('2d');
                      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
                      canvas.toBlob((blob) => {
                        const thumbnailFile = new File([blob], `${uploadFileName}.jpg`, { type: 'image/jpeg' });
                        const videoObjectUrl = URL.createObjectURL(f);
                       
                        const fileObject = {
                            local:videoObjectUrl,
                            name:f.name,
                            data:f,
                            thumbnail:thumbnailFile,
                        }
                                    
                        forLocalFileURL.push(fileObject);
                        
                       
                        resolve();
                      }, 'image/jpeg', 0.75);
                    });
                  });
              
                videoElement.remove();
                URL.revokeObjectURL(videoElement.src);
        
        /////////////////////////////////////////
        }
              
        setVideoUrls([...videoUrls , ...forLocalFileURL]);
        setLoaderHide(false);
        setSpinnerText("");
   }catch (error) {
        console.log(error);
      }
    }

    const deleteVideo = async(e) => {
      try {
         setVideoUrls((prevVideoUrls) => prevVideoUrls.filter((preVideo) => preVideo.name != e.name));
         }catch(error){
        console.log(error);
         }
    }
 
    const deleteAllVideo = () =>{
        setVideoUrls([]);
    }

    const openFileDialog = () => {
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
      };

  return (
    <>
        <LeftBar/>
        
        {loaderHide ? 
        
        <div className="loadbg">
          <div class="spinner"></div>
          <p> {spinnerText} </p>
        </div>
        :
         null
        }



        <main className='maind'>
             <div className="upoadvideo">
                <div className="container-fluid">
                   {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                   
                      <div className="row">
                         
                          <div className="col-md-6">
                                  <div className="form-container" enctype='multipart/form-data'>
                                    <div className="upload-files-container">
                                     
                                      <div className="drag-file-area" >
                                        <i className='fa fa-upload'></i>
                                        <p className="text-center">
                                          <span className="browse-files"> 
                                            <input type="file" accept='video/*' ref={fileInputRef} className="default-file-input" onChange={video_upload} multiple/> 
                                            <span className="browse-files-text">browse file from device</span> 
                                          </span> 
                                        </p>
                                      </div>

                                      <span className="cannot-upload-message"> <span className="material-icons-outlined">error</span> Please select a file first <span className="material-icons-outlined cancel-alert-button">cancel</span> </span>
                                      <div className="file-block">
                                        <div className="file-info"> <span className="material-icons-outlined file-icon">description</span> <span className="file-name"> </span> | <span className="file-size">  </span> </div>
                                        <span className="material-icons remove-file-icon">delete</span>
                                        <div className="progress-bar"> </div>
                                      </div>
                                    </div>

                                  </div>
                          </div>

                          <div className="col-md-6"> 
                              <div className='d-flex flex-wrap'>  
                                  {videoUrls ? videoUrls.map((e) => {
                                      return (
                                          <div key={e}>
                                              <div className="multipleVideoPlay position-relative">
                                                      <div className='multiple_close_icon' onClick={()=>deleteVideo(e)} >
                                                              <CloseIcon/>
                                                      </div>
                                                      <div className="previewVideo" >
                                                          <ReactPlayer
                                                              className='react-player fixed-bottom'
                                                              url= {e.local}
                                                              controls = {false}
                                                              playing	= {true}
                                                              muted = {true}
                                                          />
                                                      </div>
                                              </div>
                                          </div>
                                      );
                                  }) : null}
                              </div>

                              <div className='mt-4 text-center'>
                                   <button type="submit" className='btn btn-danger me-2' onClick={deleteAllVideo} > Delete all </button>
                                   <button type="submit" className={videoUrls.length ? 'btn btn-danger' : 'btn btn-secondary' } onClick={videoUrls.length ? onSubmit_UploadVideoData : null } > Save draft </button>
                              </div>
                          </div>

                          {/* <div className="col-md-12 text-center mt-4">
                              <button type="submit" className='btn btn-danger' onClick={deleteAllVideo} > Delete all </button>
                              <button type="submit" className={videoUrls.length ? 'btn btn-danger' : 'btn btn-secondary' } onClick={videoUrls.length ? onSubmit_UploadVideoData : null } > Save draft </button>
                         
                          </div> */}
                          
                      </div>
                      
                  
                </div>
             </div>
		    </main>
    </>
  )
}

export default BulkUpload