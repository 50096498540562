import React, { useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import DetailPage from './components/detailpage/DetailPage'
import Header from './components/common/Header'
import Footer from './components/common/Footer'
import Home from './components/home/Home'
import Search from './components/search/Search'
import VideoUploader from './components/adminpart/VideoUploader'
import BulkUpload from './components/adminpart/BulkUpload'
import CreateUser from './components/adminpart/CreateUser'
import ChangePassword from './components/adminpart/ChangePassword'
import Draft from './components/adminpart/Draft'
import EditVideoUploaderPart from './components/adminpart/EditVIdeoUploaderPart.js'
import DraftVideoEdit from './components/adminpart/DraftVideoEdit.js'
import AllUploadedVideoList from './components/adminpart/VideosList'
import ManageCategory from './components/Category/ManageCategory'
import ManageCategoryList from './components/Category/CategoryList'
import AddCategory from './components/Category/addCategory'
import AddTag from './components/Category/addTag'
import Login from "./components/login/Login"
import {categoryList} from "./utils/api-Request"
import { jwt } from "./server"
import  WrongUrl  from "./WrongUrl"



function Routers() {
  const [searchHeaderValue,setSearchHeaderValue] = useState("");
  return (
    <>
            {
              jwt ? <Header setSearchHeaderValue={setSearchHeaderValue} searchHeaderValue={searchHeaderValue}/> : null
            }
            <Routes>
             { jwt ?
               ( 
                <>
                <Route exact path= "/" element = {<Home setSearchHeaderValue={setSearchHeaderValue} searchHeaderValue={searchHeaderValue} />}/>
                <Route exact path= "/dashboard" element = {<Home setSearchHeaderValue={setSearchHeaderValue} searchHeaderValue={searchHeaderValue} />}/>
                <Route exact path = "/video-uploader" element = {<VideoUploader/>} />
                <Route exact path = "/edit-video-uploader/:id" element = {<EditVideoUploaderPart/>} />
                <Route exact path = "/draft-edit-video/:id" element = {<DraftVideoEdit/>} />
                <Route exact path = "/video-list" element = {<AllUploadedVideoList/>} /> 
                <Route exact path = "/bulk-upload" element = {<BulkUpload/>} /> 
                <Route exact path = "/draft-list" element = {<Draft/>} /> 
                <Route exact path = "/search" element = {<Search/>} />
                <Route exact path = "/detail-page/:id" element = {<DetailPage/>} />
                <Route exact path = "/create/user" element = {<CreateUser/>} />
                <Route exact path = "/change/password" element = {<ChangePassword/>} />
                <Route exact path = "/manage/category" element = {<ManageCategory/>} />
                </>
               )
               :
               (
                 <>
                 <Route path= "/login" element = {<Login/>}/>
                 <Route path= "/" element = {<Login/>}/>
                 <Route path= "*" element = {<Login/>}/>
                </>
               )
              }
            </Routes>

         {jwt ?  <Footer/> : null}
    </>
  )
}

export default Routers