import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/Simulanis-Logo.svg'
import avatar from '../../assets/images/avatar.jpg'
import { Link } from 'react-router-dom'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';

import {  logout , login_detail} from "../../server"




function Header({searchHeaderValue,setSearchHeaderValue}) {
 
  const[userName , setUserName] = useState("")
  const[userRole , setUserRole] = useState("")

  useEffect(()=>{
    setUserName(login_detail?.name)
    setUserRole(login_detail?.role)
  },[])

  return (
    <>
    <header className='header'>
      <nav className=" navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">  
          
          <div className="fgdryhg">

            <a className="navbar-brand" href="/"><img src={logo} alt="" /></a>
            
            <form className='searchfield' action="">
                {searchHeaderValue.length > 0 ? <div className='cancelButton' onClick={()=>setSearchHeaderValue("")}>X</div> : null }
                <input className='form-control' type="text" placeholder='Search...' onChange={(e) => setSearchHeaderValue(e.target.value)} value={searchHeaderValue} />
                <span className='searchbtn'><i className='fa fa-search'></i></span>
            </form>
            
            <div className="" id="">
              <ul className="navbar-nav mb-2 mb-lg-0">
                   
                  <li className="nav-item dropdown">
                      <a className="nav-link py-0 d-flex align-items-center ms-3" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <img className='avatar' src={avatar} alt="" />
                          <span className='ps-1'> {userName} </span>
                      </a>

                      <ul className="dropdown-menu  loginDropdown" aria-labelledby="navbarDropdown">
                        { userRole == "admin" && 
                            <>
                                  <li><Link className="dropdown-item d-flex" to="/video-uploader"><UploadOutlinedIcon/><span className='MuiIcons'> Video</span></Link></li>
                                  <li><Link className="dropdown-item d-flex" to="/manage/category"><AddCircleOutlineIcon/> <span className='MuiIcons'> Category</span> </Link></li>
                                  <li><Link className="dropdown-item d-flex" to="/create/user"><AddCircleOutlineIcon/><span className='MuiIcons'> Create User</span></Link></li>
                            </>
                        }
                          <li><Link className="dropdown-item d-flex" to="/change/password"><EditIcon/><span className='MuiIcons'> Change Password</span></Link></li>
                          <li><Link className="dropdown-item d-flex" href="#" onClick={logout.logout} ><LogoutOutlinedIcon/><span className='MuiIcons'> Logout</span></Link></li>
                      </ul>
                  </li>

              </ul>
            </div>

          </div>  

        </div>
      </nav>
    </header>
    </>
  )
}

export default Header