import React, { useEffect,useState } from 'react';
import HomeBannner from '../banner/HomeBannner'
import { getVideosList, userLogin } from '../../utils/api-Request';
import logo from '../../assets/images/logos.png'


const Login =()=> {


    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [wrongEmail, setWrongEmail] = useState("");
    const [wrongPassword, setWrongPassword] = useState("");


    const handleSubmit = async(e) => {
             try {
                 e.preventDefault();
                let data = {
                    email:username,
                    password:password,
                }
                let res = await userLogin(data);

            

                if(res?.data?.status){
                    localStorage.clear();
                    localStorage.setItem("userName",`${JSON.stringify(res?.data?.data.name)}`);
                    localStorage.setItem("jwt", `${JSON.stringify(res?.data?.data.token)}`);
                    localStorage.setItem("login_detail", `${JSON.stringify(res?.data?.data)}`);
                    window.location.href = "/dashboard";
                  }else{
               
                 if(res?.data.message == "User not found"){
                   setWrongEmail(res?.data.message)
                  }
                  if(res?.data.message == "Wrong password"){
                   setWrongPassword(res?.data.message)
                 }
               
                 setTimeout(() => {
                  setWrongEmail("");
                  setWrongPassword("")
                 }, 3000);

                  }

             } catch (error) {
                
             }
    };
    
    return (
        <>
       {/* <div className="sign-up-in-section">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
                <form onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="username">Username:</label>
                    <input
                      type="text"
                      id="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="password">Password:</label>
                    <input
                      type="password"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <button type="submit">Submit</button>
                </form>
            </div>
          </div>
        </div>
       </div> */}
       <section className="sign-up-in-section bg-dark ptb-60">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-9 col-12">
                        <div className="pricing-content-wrap bg-custom-light rounded-custom shadow-lg">
                            <div className="price-feature-col logignty pricing-feature-info text-white left-radius p-5 pt-4 order-1 order-lg-0">
                                <a href="/" className="mb-5 d-none d-xl-block d-lg-block"><img src={logo}  alt="logo" className="img-fluid" style={{width:'80px'}} /></a>
                                <div className="">
                                   
                                </div>
                            </div>
                            <div className="price-feature-col pricing-action-info p-5 right-radius bg-light-subtle order-0 order-lg-1">
                                <form action="#" onSubmit={handleSubmit} className="register-form">
                                    <div className="row">
                                        <div className="col-md-12">
                                           <h3>Nice to seeing you again</h3>
                                           <p>Please log in to access your account web-enabled methods of innovative niches.</p>
                                        </div>
                                        <div className="col-sm-12 mb-3">
                                              <label htmlFor="username">User name:</label>
                                              <input
                                                className='form-control'
                                                type="text"
                                                id="username"
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                              />

                                              <span className='text-danger'>{wrongEmail}</span>
                                              
                                              
                                        </div>
                                        <div className="col-sm-12 ">
                                            <label htmlFor="password">Password:</label>
                                            <input
                                              className='form-control'
                                              type="password"
                                              id="password"
                                              value={password}
                                              onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <span className='text-danger'>{wrongPassword}</span>
                                        </div>
                                        <div className="col-12">
                                            <button type="submit" className="btn btn-primary mt-4 d-block w-100">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </>
      );
}

export default Login