import React, { useEffect,useState } from 'react';
import moment from 'moment';
import thumbnail from '../../assets/video/thumbnail/firesafety.jpg'
import { Link } from 'react-router-dom';
import { videoPath } from "../../server"

const VideoThumbnailCard = ({ data }) => {

    return (
        <>
            <div className="videocard">
                <div className="videoplbx">
                    
                    <div className='videothumb'>
                         <img className='thumbnail' src={`${videoPath}${data?.thumb_path}`} alt="" />
                         <Link to={`/detail-page/${data?.id}`} className="playbtn"><i className="fas fa-play"></i></Link>
                    </div>

                    <div>
                        <h6>{data?.title}</h6>
                        <p className='d-none'>{data?.description}</p>
                       <p><span className='viewsd'>{data?.viewCount} views</span> </p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default VideoThumbnailCard