import React, { useEffect, useState, useRef } from 'react';
import { changePassword } from '../../utils/api-Request';
import { useFormik } from "formik";
import {
  TextField,
  TextareaAutosize,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Input,
  Button,
  CircularProgress
} from "@mui/material";
import { validationSchema_changePassword} from "./validation";
import { login_detail } from '../../server';


const ChangeUserPassword = () => {

    const [passwordMsg, setPasswordMsg] = useState(null);
    const [wrongPass, setWrongPass] = useState(null);
    const [countdownStart, setCountdownStart] = useState(false);
    const [countdown , setCountdown] = useState(5)

    const formik = useFormik({
    initialValues: {
        oldPassword:"",
        newPassword:"",
        id:null
    },
    validationSchema: validationSchema_changePassword,

    onSubmit: async (values) => {
        try {
            setWrongPass(null);
            setPasswordMsg(null);

            let resData = await changePassword(values);
             
            if(resData.data.status == 0){
                setWrongPass(0)
                setPasswordMsg("Old Password is wrong.")
                setTimeout(()=>{
                    { resData.data.status == 1 && (window.location.href = "/dashboard") }
                        setWrongPass(null);
                        setPasswordMsg(null);
                    },3000)
            }else{
                setWrongPass(1);
                setPasswordMsg("Password changed successfully.")
                setTimeout(()=>{
                    { resData.data.status == 1 && (window.location.href = "/dashboard") }
                        setWrongPass(null);
                        setPasswordMsg(null);
                    },1500)

            }

                
        } catch (error) {
            console.log("error")
            console.log(error)
            
        }
    }
       });

    const handleChangeInput = (event) => {
        formik.setFieldValue(event.target.name, event.target.value)
    }

    useEffect(()=>{
        formik.setFieldValue("id", login_detail.id)
        setWrongPass(null);
        setPasswordMsg(null);
      },[])

  return (
    <>
        
        <main className='maind'>
             <div className="upoadvideo">
                <div className="container-fluid">
                   <form onSubmit={formik.handleSubmit}>
                      <div className="row changePassword">
                         <h2>Change Password</h2>
                          <div className="col-6">
                                <TextField id="oldPassword" name="oldPassword" label="Old Password" type="text" value={formik.values.oldPassword} onChange={handleChangeInput} error={formik.errors.oldPassword && formik.touched.oldPassword} fullWidth variant="outlined" />
                                      {formik.errors.oldPassword && formik.touched.oldPassword && <span className='text-danger'>{formik.errors.oldPassword}</span>}
                                      {wrongPass == 0 && <p className='text-danger wrongPassword'>{passwordMsg}</p> }
                          </div>

                          <div className="col-6">
                                <TextField id="newPassword" name="newPassword" label="New Password" type="text" value={formik.values.newPassword} onChange={handleChangeInput} error={formik.errors.newPassword && formik.touched.newPassword} fullWidth variant="outlined" />
                                      {formik.errors.newPassword && formik.touched.newPassword && <span className='text-danger'>{formik.errors.newPassword}</span>}
                          </div>
                           
                          <div className="col-12">
                               <button type="submit" className='btn btn-danger' > Save </button>
                                 {wrongPass == 1 && <p className='text-success rightPassword'>{passwordMsg}</p> }
                          </div>
                          
                      </div>
                    </form>
                </div>
             </div>
	    </main>

    </>
  )
}

export default ChangeUserPassword