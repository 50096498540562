import React, {useEffect,useState} from 'react'
import Accordion from 'react-bootstrap/Accordion';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { categoryWithTagList } from '../../utils/api-Request';

export default function Filter() {

    const[catTagList,setCatTagList] = useState([]);


    const _categoryWithTagList = async()=>{
      try{
        let res = await categoryWithTagList();
        if(res?.data?.list){
               setCatTagList(res?.data?.list)
        }
      }catch(err){
         console.log(err)
      }
    }

    useEffect(()=>{
      _categoryWithTagList()
    },[]);


  return (
    <>
        <Accordion className='position-sticky top-5'>
            <h3 className='mfilter'><span className='clearall'>Clear All</span></h3>

            {catTagList.map((item,index)=>{
               return(
                <Accordion.Item eventKey={index}>
                     <Accordion.Header>{item.name}</Accordion.Header>
                     <Accordion.Body>
                    <div className='filterbx'>
                        <FormGroup>
                            {item.tags.map((e,i)=>{
                               return( <FormControlLabel control={<Checkbox  />} label={e.name} /> )
                            })}
                        </FormGroup>
                    </div>
                </Accordion.Body>
                </Accordion.Item>
               )
            })}

        </Accordion>
    </>
  )
}

// export default Filter