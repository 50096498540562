import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import ManageCategoryBar from './ManageCategoryBar';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from "react-hook-form";
import { categoryWithTagList, tagList, categoryList, assignedTagList, submitVideo } from '../../utils/api-Request';
import Select from 'react-select';
import ReactPlayer from 'react-player'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';


import { useFormik } from "formik";
import {
  TextField,
  TextareaAutosize,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Input,
  Button,
  CircularProgress
} from "@mui/material";
import { validationSchema_videoUpload } from "../adminpart/validation";
import { createNewTag , updateCategoryTagName} from '../../utils/api-Request';

const ManageCategory = () => {

  const[ _categoryList, setCategoryList] = useState([]);
  const[ _tagList, setTagList] = useState([]);

  const[ _catTagList, setCatTagList] = useState([]);
  
  const [loaderHide, setLoaderHide] = useState(false);
  const [spinnerText, setSpinnerText] = useState("");
  const [categoryId , setCategoryId] = useState(null)
  const [categoryTagId , setCategoryTagId] = useState(null)
  const [newTagName, setNewTagName] = useState("");
  const [updateTagName, setUpdateTagName] = useState("");



   const getCategoryList=async()=>{
        try{
          let res =  await categoryList();
          if(res?.status == "200"){
              if(res?.data?.status){
                setCategoryList(res?.data?.data)
              }
          }
          }catch(err){
            console.log(err)
        }
   }
    
   const getCategoryWithTagList =async()=>{
    try{
      let res = await categoryWithTagList();

        if(res?.data?.list){
              setCatTagList(res?.data?.list)

        }
    }catch(err){
       console.log(err)
    }
   }
 
   const getTagList =async() =>{
    try{
      let res = await tagList();
      if(res?.data){
        setTagList(res?.data?.list);
      }
    }catch(err){
       console.log(err)
    }
   }
 

   useEffect(()=>{
       setLoaderHide();
       setSpinnerText("");

        // getCategoryList();
        getCategoryWithTagList();
        // getTagList();

     },[])
     
  const CreateTag = async(e) =>{
      let data={
        name : newTagName,
        categoryID:categoryId
      }

      let resData = await createNewTag(data)
      if(resData.data.status == 1){
        getCategoryWithTagList()
      }
      setNewTagName("");
      setCategoryId(null);
  }

  const UpdateTag = async(e) =>{
      let data={
        name : updateTagName,
        id:categoryTagId
      }

      let resData = await updateCategoryTagName(data);

      console.log("resData");
      console.log(resData);
      if(resData.data.status == 1){
         getCategoryWithTagList()
      }

      setUpdateTagName("");
      setCategoryTagId(null);
  }

  const activeUpdateTagName = (e,n) =>{
    setUpdateTagName(n);
    setCategoryTagId(e);
  }

  return (
    <>
        {loaderHide ? 
        
        <div className="loadbg">
          <div class="spinner"></div>
          <p> {spinnerText} </p>
        </div>
        :
         null
        }
        <main className='maind'>
             <div className="upoadvideo">
                <div className="container-fluid">
                   
                  <div className="row">
                                      { _catTagList.map((e,i)=>{
                                        return(
                                          <>
                                                <div className="col-md-3">
                                                     
                                                    <div className="form-group mb-3">
                                                        <label className="categoryName AddCategoryTag">
                                                           <h6> {e.name}<sup>*</sup> </h6> 
                                                            {e.id == categoryId ?  <span onClick={()=>setCategoryId(0)} className='AddCategoryTagButton'> <CancelIcon/> </span> : <span onClick={()=>setCategoryId(e.id)} className='AddCategoryTagButton'> <AddCircleOutlineIcon/> </span> } 
                                                        </label>
                                                        <div >
                                                       
                                                        {e.id == categoryId && 
                                                          <div className='saveNewTag mt-2'> 
                                                            <TextField size="small" id="newTagName" name="newTagName" value={newTagName} onChange={(e)=>setNewTagName(e.target.value)} label="Create Tag" type="text" variant="outlined" fullWidth /> 
                                                            <SaveIcon className="saveIcon" title="Save" onClick={CreateTag}/>
                                                          </div>
                                                        }
                                                    <div className="categoryTagsList">
                                                        {e.tags.map((tag, index) => (
                                                          <div key={index}>
                                                            <label className="tagName">
                                                              {/* {tag.name} */}
                                                              {/* <input size="small" id="newTagName" name="newTagName" value={tag.name} label="Create Tag" type="text" variant="outlined" fullWidth />  */}
                                                             
                                                              {tag.id == categoryTagId ?  
                                                              <>
                                                                    <input size="small" id="newTagName" name="newTagName" value={updateTagName} onChange={(e)=>setUpdateTagName(e.target.value)} label="Create Tag" type="text" variant="outlined" fullWidth /> 
                                                                    <SaveIcon className="saveIcon" title="Update" onClick={UpdateTag} />
                                                                    <span onClick={()=>setCategoryTagId(0)} className='AddCategoryTagButton'> <CancelIcon/> </span>
                                                              </>
                                                              : 
                                                              <>
                                                                    {tag.name}
                                                                    <span onClick={()=>activeUpdateTagName(tag.id , tag.name)} className='AddCategoryTagButton'> <EditIcon/> </span> 
                                                              </>
                                                              } 
                                                           
                                                               
                                                              
                                                              </label>
                                                          </div>
                                                         ))}
                                                    </div>                    
                                                        </div>

                                                    </div>
                                                </div>
                                          </>
                                        )
                                      })}
                  </div>
                   
                </div>
             </div>
		    </main>
    </>
  )
}

export default ManageCategory