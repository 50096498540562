import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import LeftBar from './LeftBar'
import { Link } from 'react-router-dom'
import ReactPaginate from "react-paginate";
import {draftVideosList, deleteDraftVideo, multipleDeleteVideo, tagList} from "../../utils/api-Request";
import { videoPath } from "../../server"
import thumbnail from '../../assets/video/thumbnail/firesafety.jpg'

const AllUploadedVideoList = () =>{

  const[videosList,setVideosList] = useState([]);
  const[searchVideosList,setSearchVideosList] = useState("");
  const[_tagList, setTagList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [multiDeleteIdList,setMultiDeleteIdList] = useState([]);
  const [page, setPage] = useState(1);

    const getDraftVideoList = async(data)=>{
        try {
            let res = await draftVideosList(data);
            setVideosList(res?.data?.data);
            setTotalPages(res?.data?.totalPages);
        } catch (error) {
            
        }
    }

      useEffect(()=>{
        
        let data={
            search:searchVideosList,
            pageLimit:15,
            page,
            order: [["createdAt", "DESC"]],
        };

        getDraftVideoList(data);

    },[searchVideosList,page])


    useEffect(()=>{
        setMultiDeleteIdList([]);
     },[])

    const delete_video =async(e)=>{
               try {
               
                if (window.confirm("Are you sure you want to delete this video?")) {
                        let data={
                            videoId:e
                        };

                        let res  = await deleteDraftVideo(data);
                        
                        if(res?.data?.status == 1  ){
                            Swal.fire('Video has been deleted successfully');
                                   setTimeout(()=>{
                                   window.location.reload();
                                   },1000)
                        }
            }
               } catch (error) {
                
               }
    }

    const _searchVideo = (e) =>{
        setPage(1);
      setSearchVideosList(e.target.value);
    }

    const handlePageClick = (data) => {
        setPage(data?.selected + 1);
      };

    return (
                <>
                    <LeftBar/>
                    <main className='maind'>
                        <div className="upoadvideo">
                             
                            <div className="table-reaponsive">
                                <table className='table table-bordered'>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Thumbnail</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                       {videosList.length && videosList.map((e,i)=>{
                                        return(
                                            <tr key={i}>
                                                <td>{i+1}</td>
                                                <td><img className='thumbnaillist' src={`${videoPath}${e?.thumb_path}`} alt="" /> </td>
                                                <td>
                                                    <Link className='text-primary' to={`/draft-edit-video/${e.id}`}>
                                                        <i className='fa fa-edit pe-2'></i>
                                                    </Link>
                                                    | 
                                                    <span className='text-danger hoverBtn' onClick={()=>delete_video(e.id)}>
                                                        <i className='fa fa-trash-alt ps-2'></i>
                                                    </span>
                                                </td>
                                            </tr>
                                           )
                                         })}
                                      
                                        
                                    </tbody>
                                </table>
                                  
                                <div className="col-12">
                                    <ReactPaginate
                                      breakLabel="..."
                                      className="pagination"
                                      nextLabel={
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                      }
                                      onPageChange={handlePageClick}
                                      pageRangeDisplayed={5}
                                      pageCount={totalPages}
                                      previousLabel={
                                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                                      }
                                      renderOnZeroPageCount={null}
                                    />
                              </div>

                            </div>
                        </div>
                    </main>
                </>
      )

}

export default AllUploadedVideoList