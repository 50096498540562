import React, { useEffect,useState } from 'react';
import HomeBannner from '../banner/HomeBannner'
import Filter from '../filter/Filter'
import VideoThumbnailCard from '../VideoThumbnailCard/VideoThumbnailCard';

import pharmaceutical from '../../assets/images/genres/pharmaceutical.png'
import automotive from '../../assets/images/genres/automotive.png'
import automation from '../../assets/images/genres/automation.png'
import paintchemicals from '../../assets/images/genres/paintchemicals.png'
import oilgas from '../../assets/images/genres/oilgas.png'
import energy from '../../assets/images/genres/energy.png'
import construction from '../../assets/images/genres/construction.png'
import renewable from '../../assets/images/genres/renewable.png'

import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import FormGroup from '@mui/material/FormGroup';
import ReactPaginate from "react-paginate";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { categoryWithTagList, getVideosList, industryTagVideoCount} from '../../utils/api-Request';
import MostViewed from "../mostViewed/MostViewed"

const Industry=(props)=> {

  const [categoryCount,setCategoryCount] = useState({});
 
  const [_pharmCount,setPharmCount] = useState(0);
  const [_automotive,setAutomotiveCount] = useState({});
  const [_automation,setAutomationCount] = useState({});
  const [_paintCount,setPaintCountCount] = useState({});
  const [_oilgasCount,setOilgasCountCount] = useState({});
  const [_energyCount,setEnergyCountCount] = useState({});
  const [_constructionCount,setConstructionCount] = useState({});
  const [_renewableCount,setRenewableCount] = useState({});


  const _getIndustryTagsVideoCount = async()=> {
    try {
     let res = await industryTagVideoCount();
   
      if(res?.data?.data){
          setCategoryCount(res?.data?.data);
          res?.data?.data.map((e)=>{

            if(e.id == 1){
                setPharmCount(e)
              }
            if(e.id == 2){
                setAutomotiveCount(e)
              }
            if(e.id == 3){
                setAutomationCount(e)
              }
            if(e.id == 4){
                setPaintCountCount(e)
              }
            if(e.id == 5){
                setOilgasCountCount(e)
              }
            if(e.id == 56){
                setEnergyCountCount(e)
              }
            if(e.id == 57){
                setConstructionCount(e)
              }
            if(e.id == 58){
                setRenewableCount(e)
              }

          });
        }

    } catch (error) {
      
    }
  }

  useEffect(()=>{
     _getIndustryTagsVideoCount();
  },[]);

  return (
    <>
       
        <section className='industriessection pt-2'>
            <div className="container">
                      <div className='videolistthumb'>
                                  <h1 className='d-flex align-items-center justify-content-between'>Industry</h1>
                                  <div className="row">
                                      
                                      <div className="col-md-3" onClick={()=>{props.industryAll(_pharmCount)}}>
                                              <div className="indubox" >
                                                <img src={pharmaceutical} alt="" />
                                                <h3>Pharmaceutical <span>{_pharmCount.count} videos</span></h3>
                                              </div>
                                      </div>

                                      <div className="col-md-3" onClick={()=>{props.industryAll(_automotive)}}>
                                            <div className="indubox">
                                              <img src={automotive} alt="" />
                                              <h3>Automotive <span>{_automotive.count} videos</span></h3>
                                            </div>
                                      </div>

                                      <div className="col-md-3" onClick={()=>{props.industryAll(_automation)}} >
                                            <div className="indubox">
                                              <img src={automation} alt="" />
                                              <h3>Automation <span>{_automation.count} videos</span></h3>
                                            </div>                                      
                                      </div>

                                      <div className="col-md-3" onClick={()=>{props.industryAll(_paintCount)}} >
                                            <div className="indubox">
                                              <img src={paintchemicals} alt="" />
                                              <h3>Paint & Chemicals <span>{_paintCount.count} videos</span></h3>
                                            </div>
                                      </div>

                                      <div className="col-md-3" onClick={()=>{props.industryAll(_oilgasCount)}} >
                                            <div className="indubox">
                                              <img src={oilgas} alt="" />
                                              <h3>Oil & Gas <span>{_oilgasCount.count} videos</span></h3>
                                            </div>
                                      </div>

                                      <div className="col-md-3" onClick={()=>{props.industryAll(_energyCount)}} >
                                            <div className="indubox">
                                              <img src={energy} alt="" />
                                              <h3>Energy and Utilities <span>{_energyCount.count} videos</span></h3>
                                            </div>
                                      </div>

                                      <div className="col-md-3" onClick={()=>{props.industryAll(_constructionCount)}} >
                                            <div className="indubox">
                                              <img src={construction} alt="" />
                                              <h3>Construction <span>{_constructionCount.count} videos</span></h3>
                                            </div>
                                      </div>

                                      <div className="col-md-3" onClick={()=>{props.industryAll(_renewableCount)}} >
                                            <div className="indubox">
                                              <img src={renewable} alt="" />
                                              <h3>Renewables <span>{_renewableCount.count} videos</span></h3>
                                            </div>
                                          
                                      </div>


                                      {/* {categoryCount.map((e,i)=>{
                                         return(
                                          <div className="col-md-3" onClick={()=>{props.industryAll(e.id)}} >
                                            <div className="indubox">
                                              <img src={renewable} alt="" />
                                              <h3>{e.name} <span>{e.count} videos</span></h3>
                                            </div>
                                          </div>
                                         )
                                      })} */}

                                  </div>
                      </div>
            </div>
        </section>

 
    </>
  )
}

export default Industry