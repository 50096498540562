import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import LeftBar from './LeftBar';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from "react-hook-form";
import { categoryWithTagList, tagList, categoryList, assignedTagList, submitVideo } from '../../utils/api-Request';
import Select from 'react-select';
import ReactPlayer from 'react-player'

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import { login_detail } from '../../server';


import { useFormik } from "formik";
import {
  TextField,
  TextareaAutosize,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Input,
  Button,
  CircularProgress
} from "@mui/material";
import { validationSchema_videoUpload } from "./validation";

const VideoUploader = () => {

  const dragFileArea = useRef(null);
  const[_categoryList, setCategoryList] = useState([]);
  const[_tagList, setTagList] = useState([]);
  const[_catTagList,setCatTagList] = useState([]);
  
  const[indTagSugList,setIndTagSugList] = useState([]);
  const[techTagSugList,setTechTagSugList] = useState([]);
  const[useCaseTagSugList,setUseCaseTagSugList] = useState([]);
  const[prodTagSugList,setProdTagSugList] = useState([]);

  const [indSelectedOptions, setIndSelectedOptions] = useState([]);
  const [techSelectedOptions, setTechSelectedOptions] = useState([]);
  const [useCaseSelectedOptions, setUseCaseSelectedOptions] = useState([]);
  const [prdSelectedOptions, setPrdSelectedOptions] = useState([]);

  const [formVideoData, setFormVideoData] = useState({});
  const [fromImageData, setFromImageData] = useState({});

  const [videoUrl, setVideoUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");

  const [loaderHide, setLoaderHide] = useState(false);
  const [spinnerText, setSpinnerText] = useState("");

  const [uploadVideoError, setUploadVideoError] = useState("");


  const formik = useFormik({
    initialValues: {
       title:"",
       description:"",
       youtubeURL:"",
       indTag:[],
       techTag:[],
       useCaseTag:[],
       prdTag:[],
       clientTag:[],
       makeBanner: "false",
    },
    // validationSchema: validationSchema_videoUpload,

    onSubmit: async (values) => {
        try {
          if(videoUrl){
            onSubmit_UploadVideoData(values);
          }else{
            setUploadVideoError("Please select video before upload.")
          }
        } catch (error) {
            console.log("error")
            console.log(error)
            
        }
    }
     });

  const onSubmit_UploadVideoData = async(values)=>{
      try {

              const formData = new FormData();
                formData.append('file', formVideoData);
                formData.append('thumbnail', fromImageData);
                formData.append('jsonData', JSON.stringify(values));
                setLoaderHide(true);
                setSpinnerText("Uploading.....");

              let res= await submitVideo(formData);
               
              if(res?.data){
                setLoaderHide(false);
                setSpinnerText("");
                window.location.reload()
              }

      } catch (error) {
        
      }
     }

    const handleDragEnter = (e) => {
      e.preventDefault();
      dragFileArea.current.classList.add('drag-file-area-active');
    };
  
    const handleDragOver = (e) => {
      e.preventDefault();
    };
  
    const handleDragLeave = (e) => {
      e.preventDefault();
      dragFileArea.current.classList.remove('drag-file-area-active');
    };

    const handleDrop = (e) => {
      setVideoUrl("");
      setFormVideoData({});

      setThumbnailUrl("");
      setFromImageData({});

      const file = e.dataTransfer.files[0];
      setFormVideoData(file)
      const videoObjectUrl = URL.createObjectURL(file);
      setVideoUrl(videoObjectUrl);
      dragFileArea.current.classList.remove('drag-file-area-active');

      //////////////////////////////////////////////
        const videoElement = document.createElement('video');
        videoElement.src = URL.createObjectURL(file);
        videoElement.addEventListener('loadedmetadata', () => {
          videoElement.currentTime = 2;  // take thumbnail video 2 second
        });
        videoElement.addEventListener('seeked', () => {
          const canvas = document.createElement('canvas');
          canvas.width = videoElement.videoWidth;
          canvas.height = videoElement.videoHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          canvas.toBlob((blob) => {

            const thumbnailFile = new File([blob], 'thumbnail.jpg', { type: 'image/jpeg' });
            setFromImageData(thumbnailFile);
            const imageObjectUrl = URL.createObjectURL(thumbnailFile);
            setThumbnailUrl(imageObjectUrl);

          }, 'image/jpeg', 0.75);
        });
      //////////////////////////////////////////////

      e.preventDefault();
     
    };

   const getCategoryList=async()=>{
        try{
          let res =  await categoryList();
          if(res?.status == "200"){
              if(res?.data?.status){
                setCategoryList(res?.data?.data)
              }
          }
          }catch(err){
            console.log(err)
        }
   }
    
   const getCategoryWithTagList =async()=>{
    try{
      let res = await categoryWithTagList();
      if(res?.data?.list){
             setCatTagList(res?.data?.list)
      }
    }catch(err){
       console.log(err)
    }
   }
 
   const getTagList =async() =>{
    try{
      let res = await tagList();
      if(res?.data){
        setTagList(res?.data?.list);
      }
    }catch(err){
       console.log(err)
    }
   }
 
   const getAssignedTagList =async() =>{
    try{
      let data={
        userId:login_detail.id
      }
      let res = await assignedTagList(data);

      if(res.data.data){
        res.data.data.assignedTagList.forEach(category => {
          if (category.categoryID === '1') {
            setIndTagSugList(prevState => [...prevState, category]);
          } else if (category.categoryID === '2') {
            setTechTagSugList(prevState => [...prevState, category]);
          } else if (category.categoryID === '3') {
            setUseCaseTagSugList(prevState => [...prevState, category]);
          } else if (category.categoryID === '4') {
            setProdTagSugList(prevState => [...prevState, category]);
          }
        });

      }

    }catch(err){
       console.log(err)
    }
   }

   useEffect(()=>{
       setLoaderHide();
       setSpinnerText("");

        getCategoryList();
        getCategoryWithTagList();
        getTagList();
        getAssignedTagList();
     },[])
     
   const KeyCodesProd = {
               comma: 188,
               enter: 13
             };

    const [tags, setTags] = React.useState([]);
    const [clients, setClients] = React.useState([]);
    const delimitersCli = [KeyCodesProd.comma, KeyCodesProd.enter];

    const [suggestionsCli , setSuggestionsCli] = React.useState([
                // { id: 'Metaverse', text: 'Metaverse' },
            ])

    const handleDeleteCli = (i) => {
            setClients(clients.filter((tag, index) => index !== i));
            formik.setFieldValue('clientTag', clients.filter((tag, index) => index !== i))
          };

    const handleAdditionCli = (tag) => {
            setClients([...clients, tag]);
            formik.setFieldValue('clientTag', [...clients, tag])
          };

    const handleDragCli = (tag, currPos, newPos) => {
            const newTags = Array.from(clients);
            newTags.splice(currPos, 1);
            newTags.splice(newPos, 0, tag);
            setClients(newTags);
            formik.setFieldValue('clientTag', newTags)
          };

    const handleTagClick = (index) => {
            console.log('The tag at index ' + index + ' was clicked');
          };

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = data => console.log(data);

    const indHandleMultiSelect = (e) => {
       
        if (Array.isArray(e)) {
          formik.setFieldValue('indTag', e)
          setIndSelectedOptions(e);
        }else {
          formik.setFieldValue('indTag', [e])
          setIndSelectedOptions([e]);
        }
      };

    const techHandleMultiSelect = (e) => {
        setTechSelectedOptions(e);
        formik.setFieldValue('techTag', e)
      };

    const useCaseHandleMultiSelect = (e) => {
        setUseCaseSelectedOptions(e);
        formik.setFieldValue('useCaseTag', e)
      };

    const prdHandleMultiSelect = (e) => {
        setPrdSelectedOptions(e);
        formik.setFieldValue('prdTag', e)
      };
      
    const handleChangeInput = (event) => {
        formik.setFieldValue(event.target.name, event.target.value)
    }

    const video_upload = async() =>{
      try {
        setVideoUrl("");
        setFormVideoData({});

        setThumbnailUrl("");
        setFromImageData({});

        const fileInput = document.querySelector('.default-file-input');
        const file = fileInput.files[0];
        setFormVideoData(file);
        const videoObjectUrl = URL.createObjectURL(file);
        setVideoUrl(videoObjectUrl);

        /////////////////////////////////////////
        const videoElement = document.createElement('video');
        videoElement.src = URL.createObjectURL(file);
        videoElement.addEventListener('loadedmetadata', () => {
          videoElement.currentTime = 2;  // take thumbnail video 2 second
        });
        videoElement.addEventListener('seeked', () => {
          const canvas = document.createElement('canvas');
          canvas.width = videoElement.videoWidth;
          canvas.height = videoElement.videoHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          canvas.toBlob((blob) => {

            const thumbnailFile = new File([blob], 'thumbnail.jpg', { type: 'image/jpeg' });
            setFromImageData(thumbnailFile);
            const imageObjectUrl = URL.createObjectURL(thumbnailFile);
            setThumbnailUrl(imageObjectUrl);

          }, 'image/jpeg', 0.75);
        });
        /////////////////////////////////////////

   }catch (error) {
        
      }
    }

    const deleteUploadVideo = async() => {
      try {
        setVideoUrl("");
        setFormVideoData({});

        setThumbnailUrl("");
        setFromImageData({});
      } catch (error) {
        
      }
    }
 
    const makeIndustryBanner = ()=>{
        formik.setFieldValue("indTag", [])
        formik.values.makeBanner == "false" ?  formik.setFieldValue("makeBanner", "true") : formik.setFieldValue("makeBanner", "false")
      }


  return (
    <>
        <LeftBar/>
        {loaderHide ? 
        
        <div className="loadbg">
          <div class="spinner"></div>
          <p> {spinnerText} </p>
        </div>
        :
         null
        }
        <main className='maind'>
             <div className="upoadvideo">
                <div className="container-fluid">
                   {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                   <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                         
                          <div className="col-lg-6">
                              {formVideoData && videoUrl ? (
                                    <div className="videoPlay">
                                        <div className='close_icon' onClick={deleteUploadVideo}>
                                           <CloseIcon/>
                                        </div>
                                        <div className="previewVideo" >
                                            <ReactPlayer
                                                className='react-player fixed-bottom'
                                                url= {videoUrl}
                                                width='100%'
                                                height='100%'
                                                controls = {true}
                                                playing	= {true}
                                              />
                                        </div>
                                    </div>
                                   )
                                   :
                                  <div className="form-container" enctype='multipart/form-data'>
                                    <div className="upload-files-container">
                                      
                                      <div ref={dragFileArea} className="drag-file-area" onDragEnter={handleDragEnter} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} >
                                        <i className='fa fa-upload'></i>
                                        <h3 className="dynamic-message"> Drag & drop any Video here </h3>
                                        <br />
                                        <p className='text-center'>or</p>
                                        <p className="text-center"> 
                                          <span className="browse-files"> 
                                              <input type="file" accept='video/*' className="default-file-input" onChange={video_upload} /> 
                                              <span className="browse-files-text">browse file from device</span> 
                                          </span> 
                                        </p>
                                      </div>

                                      <span className="cannot-upload-message"> <span className="material-icons-outlined">error</span> Please select a file first <span className="material-icons-outlined cancel-alert-button">cancel</span> </span>
                                      <div className="file-block">
                                        <div className="file-info"> <span className="material-icons-outlined file-icon">description</span> <span className="file-name"> </span> | <span className="file-size">  </span> </div>
                                        <span className="material-icons remove-file-icon">delete</span>
                                        <div className="progress-bar"> </div>
                                      </div>
                                    </div>

                                    {uploadVideoError ? <p>Please Select Video.</p> : null}

                                  </div>
                                  }
                            

                          </div>

                          <div className="col-lg-6">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group mb-3">
                                        <TextField id="title" name="title" label="Title" type="text" value={formik.values.title} onChange={handleChangeInput} error={formik.errors.title && formik.touched.title} fullWidth variant="outlined" />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group mb-3">
                                      <TextField id="description" name="description" label="Description" type="text" value={formik.values.description} onChange={handleChangeInput} error={formik.errors.description && formik.touched.description} fullWidth variant="outlined" multiLine rows={4} />
                                    </div>
                                </div>

                                <div className="col-md-5">
                                  <div className="form-group mb-3">
                                         <FormGroup>
                                            <FormControlLabel control={<Checkbox  checked={formik.values.makeBanner == "true" ? true : false} onChange={makeIndustryBanner} />}  label="Make Industry Banner" />
                                        </FormGroup>
                                  </div>
                                </div>
                                <div className="col-md-7">
                                  <div className="form-group mb-3">
                                      <TextField  
                                      id="outlined-basic" 
                                      name="youtubeURL" 
                                      label="YouTube url" 
                                      value={formik.values.youtubeURL} 
                                      onChange={handleChangeInput} 
                                      error={formik.errors.youtubeURL && formik.touched.youtubeURL} 
                                      variant="outlined" 
                                      fullWidth 
                                      />
                                  </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group mb-3">
                                      <label>Industry<sup>*</sup></label>
                                      <Select
                                        name="Industry"
                                        options={indTagSugList}
                                        isMulti = {formik.values.makeBanner == "false" ? true : false}
                                        value={formik.values.indTag}
                                        onChange={indHandleMultiSelect}
                                        className={formik.touched.indTag && formik.errors.indTag ? 'is-invalid' : ''}
                                      />
                                  
                                      {formik.touched.indTag && formik.errors.indTag && (<div className="invalid-feedback">{formik.errors.indTag}</div>)}

                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group mb-3">
                                          <label>Technology<sup>*</sup></label>
                                          <Select
                                               options={techTagSugList}
                                               isMulti
                                              //  value={techSelectedOptions}
                                              value={formik.values.techTag}
                                               onChange={techHandleMultiSelect}
                                               className={formik.touched.techTag && formik.errors.techTag ? 'is-invalid' : ''}
                                          />
                                          {formik.touched.techTag && formik.errors.techTag && (<div className="invalid-feedback">{formik.errors.techTag}</div>)}
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group mb-3">
                                          <label>Use Case<sup>*</sup></label>
                                          <Select
                                              options={useCaseTagSugList}
                                              isMulti
                                              // value={useCaseSelectedOptions}
                                              value={formik.values.useCaseTag}
                                              onChange={useCaseHandleMultiSelect}
                                              className={formik.touched.useCaseTag && formik.errors.useCaseTag ? 'is-invalid' : ''}
                                          />
                                          {formik.touched.useCaseTag && formik.errors.useCaseTag && (<div className="invalid-feedback">{formik.errors.useCaseTag}</div>)}
                                          {/* <p>{formik.errors.useCaseTag}</p> */}
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group mb-3">
                                          <label>Product Category<sup>*</sup></label>
                                          <Select
                                              options={prodTagSugList}
                                              isMulti
                                              // value={prdSelectedOptions}
                                              value={formik.values.prdTag}
                                              onChange={prdHandleMultiSelect}
                                              className={formik.touched.prdTag && formik.errors.prdTag ? 'is-invalid' : ''}
                                          />
                                          {formik.touched.prdTag && formik.errors.prdTag && (<div className="invalid-feedback">{formik.errors.prdTag}</div>)}
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className={formik.touched.clientTag && formik.errors.clientTag ? 'is-invalid form-group mb-2' : 'form-group mb-2'}>
                                          <label>Clients<sup>*</sup></label>
                                          <ReactTags
                                            tags={formik.values.clientTag}
                                            suggestions={suggestionsCli}
                                            delimiters={delimitersCli}
                                            handleDelete={handleDeleteCli}
                                            handleAddition={handleAdditionCli}
                                            handleDrag={handleDragCli}
                                            handleTagClick={handleTagClick}
                                            inputFieldPosition="top"
                                            autocomplete
                                            editable={false}
                                            placeholder={false}
                                            
                                          />
                                    </div>
                              {formik.touched.clientTag && formik.errors.clientTag && (<div className="invalid-feedback">{formik.errors.clientTag}</div>) }
                                </div>
                                 
                            </div>
                          </div>



                          <div className="col-md-12 text-end mt-4">
                              {/* <button type="submit" className='btn btn-danger' onClick={SubmitData} >Save </button> */}
                              <button type="submit" className='btn btn-danger' > Save </button>
                          </div>
                          
                      </div>
                    </form>
                </div>
             </div>
		    </main>
    </>
  )
}

export default VideoUploader