import React from 'react'
import { Link, NavLink } from 'react-router-dom';


function ManageCategoryBar() {
  return (
    <>
         <nav id="sidebar-nav">
            <ul id="vertical-sidebar-nav" className="sf-menu sf-js-enabled sf-arrows" style={{touchAction: 'pan-y'}}>
              
              <li className="normal-item-pro">
                <NavLink to="/manage/category_list" >
                    <span className="icon-File-Video"></span>
                    Category List
                </NavLink>
              </li>

              <li className="normal-item-pro">
                <NavLink to="/manage/add_category" >
                    <span className="icon-Upload"></span>
                 Add Category
                </NavLink>
              </li>
              <li className="normal-item-pro">
                <NavLink to="/manage/add_tag" >
                  <span className="icon-Upload"></span>
                  Add tag
                </NavLink>
              </li>
            </ul>
            <div className="clearfix"></div>
        </nav>
    </>
  )
}

export default ManageCategoryBar