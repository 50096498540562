import React from 'react'
import Filter from '../filter/Filter'
import Filter2 from '../filter/Filter2'
import GridViewIcon from '@mui/icons-material/GridView';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import VideoThumbnailCard from '../VideoThumbnailCard/VideoThumbnailCard'
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

function Search() {
  return (
    <>
        <section className="searchitem">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <Filter/>
                    </div>
                    <div className="col-md-9">
                        <div className='ps-4'>
                            <Filter2/>
                            <hr />
                            <div className='searchdata'>
                                <div className="row">
                                    <div className="col-md-8">
                                        <h1>Your Searched Result</h1>
                                    </div>
                                    <div className="col-md-4">
                                        <ul className="nav nav-pills justify-content-end" id="pills-tab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link py-1 p-2 active" id="pills-grid" data-bs-toggle="pill" data-bs-target="#gridview" type="button" role="tab" aria-controls="pills-home" aria-selected="true"> <GridViewIcon /> </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link py-1 p-2" id="pills-list" data-bs-toggle="pill" data-bs-target="#listviewid" type="button" role="tab" aria-controls="pills-profile" aria-selected="false"><FormatListBulletedOutlinedIcon/> </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="tab-content mt-4" id="pills-tabContent">
                                   
                                    <div className="tab-pane fade show active" id="gridview" role="tabpanel" aria-labelledby="pills-grid">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <VideoThumbnailCard/>
                                            </div>
                                            <div className="col-md-4">
                                                <VideoThumbnailCard/>
                                            </div>
                                            <div className="col-md-4">
                                                <VideoThumbnailCard/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="tab-pane fade" id="listviewid" role="tabpanel" aria-labelledby="pills-list">
                                        <div className="row desblock listview">
                                            <div className="col-md-12">
                                                <VideoThumbnailCard/>
                                            </div>
                                            <div className="col-md-12">
                                                <VideoThumbnailCard/>
                                            </div>
                                            <div className="col-md-12">
                                                <VideoThumbnailCard/>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* <Player
                                autoplay
                                loop
                                src="https://assets4.lottiefiles.com/packages/lf20_yuisinzc.json"
                                style={{ height: '300px', width: '300px' }}
                                >
                                <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                            </Player> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Search