import React from 'react'
import logo from '../../assets/images/Simulanis-Logo.png'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
        <footer className='footer'>
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <h4>Products</h4>
                        <ul>
                            <li><Link to="#"> Learn </Link></li>
                            <li><Link to="#"> Academy </Link></li>
                            <li><Link to="#"> Metaverse </Link></li>
                            <li><Link to="#"> Productivity Suite </Link></li>
                            <li><Link to="#"> Creator </Link></li>
                            <li><Link to="#"> Simulators</Link></li>
                            <li><Link to="#"> Sanalexa </Link></li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h4>Industry</h4>
                        <ul>
                            <li><Link to="#"> Pharmaceutical </Link></li>
                            <li><Link to="#"> Automotive </Link></li>
                            <li><Link to="#">Automation </Link></li>
                            <li><Link to="#">Paint and Chemical </Link></li>
                            <li><Link to="#">Oil and Gas </Link></li>
                            <li><Link to="#">Education </Link></li>
                            <li><Link to="#">Safety Training </Link></li>
                            <li><Link to="#">Renewables  </Link></li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h4>Technology</h4>
                        <ul>
                            <li><Link to="#"> AR </Link></li>
                            <li><Link to="#"> VR </Link></li>
                            <li><Link to="#">MR </Link></li>
                            <li><Link to="#">Walkthrough</Link></li>
                            <li><Link to="#">Videos & Animations </Link></li>
                        </ul>
                    </div>

                    <div className="col-md-3">
                        <h4>Use Cases</h4>
                        <ul>
                            <li><Link to="#">Operation Training </Link></li>
                            <li><Link to="#">Troubleshooting and Maintenance Training </Link></li>
                            <li><Link to="#">Safety/EHS Training </Link></li>
                            <li><Link to="#">Repair Training </Link></li>
                            <li><Link to="#">Productivity </Link></li>
                            <li><Link to="#">Experiential Marketing </Link></li>
                            <li><Link to="#">Consultancy </Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr className=' mt-4' style={{borderTop:'1px solid #ffffff63'}} />
            <div className="footer-bottom">
                <div className="continer">
                    <p className='text-center mb-0'>© 2023 Simulanis Solutions Pvt. Ltd. All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    </>
  )
}

export default Footer