import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import LeftBar from './LeftBar'
import { Link } from 'react-router-dom'
import ReactPaginate from "react-paginate";
import {getVideosList, deleteVideo, multipleDeleteVideo, tagList} from "../../utils/api-Request";
import { videoPath } from "../../server"
import thumbnail from '../../assets/video/thumbnail/firesafety.jpg'

const AllUploadedVideoList = () =>{

  const [videosList,setVideosList] = useState([]);
  const [searchVideosList,setSearchVideosList] = useState("");
  const [loaderHide, setLoaderHide] = useState(false);
  const [spinnerText, setSpinnerText] = useState("");
  const [_tagList, setTagList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [multiDeleteIdList,setMultiDeleteIdList] = useState([]);
  const [page, setPage] = useState(1);

    const getVideoList = async(data)=>{
        try {
            let res = await getVideosList(data);
            setVideosList(res?.data?.data);
            setTotalPages(res?.data?.totalPages);
        } catch (error) {
            
        }
    }

    const getTagList =async() =>{
        try{
          let res = await tagList();
          if(res?.data){
            setTagList(res?.data?.list);
          }
        }catch(err){
           console.log(err)
        }
       }

    useEffect(()=>{
        setLoaderHide(false);
        setSpinnerText("");
      },[])

    useEffect(()=>{
        
        let data={
            search:searchVideosList,
            pageLimit:15,
            page,
            order: [["createdAt", "DESC"]],
        };

        getVideoList(data);

    },[searchVideosList,page])

    useEffect(()=>{
        setMultiDeleteIdList([]);
        getTagList(); 
     },[])

    const delete_video =async(e)=>{
               try {
               
                if (window.confirm("Are you sure you want to delete this video?")) {
                        let data={
                            videoId:e
                        };

                        let res  = await deleteVideo(data);
                        
                        if(res?.data?.status == 1  ){
                            Swal.fire('Video has been deleted successfully');
                                   setTimeout(()=>{
                                   window.location.reload();
                                   },2000)
                        }
            }
               } catch (error) {
                
               }
    }

    const _searchVideo = (e) =>{
        setPage(1);
      setSearchVideosList(e.target.value);
    }

    const handlePageClick = (data) => {
        setPage(data?.selected + 1);
      };
    

    const multipleDeleteId = (e) =>{
        console.log(e);
        const index = multiDeleteIdList.indexOf(e);

        if (index !== -1) {
          const updatedMultiDelete = [...multiDeleteIdList];
          updatedMultiDelete.splice(index, 1);
          setMultiDeleteIdList(updatedMultiDelete);
        } else {
          setMultiDeleteIdList(prevMultiDelete => [...prevMultiDelete, e]);
        }

    }

    const multipleDelete = async(e) =>{

        if (window.confirm("Are you sure you want to delete all selected videos?")) {
            setLoaderHide(true);
            setSpinnerText("Deleting.....");

            let data={
                videoId:multiDeleteIdList
            };

            let res  = await multipleDeleteVideo(data);
            if(res?.data?.status == 1  ){
                setLoaderHide(false);
                setSpinnerText("");
                Swal.fire("Video's has been deleted successfully");
                       setTimeout(()=>{
                       window.location.reload();
                       },2000)
            }
        }
    }


    return (
                <>
                    <LeftBar/>
                    {loaderHide ? 
                        <div className="loadbg">
                        <div class="spinner"></div>
                        <p> {spinnerText} </p>
                        </div>
                        :
                        null
                    }

                    <main className='maind'>
                        <div className="upoadvideo">
                            <div className="row mb-2">
                                <div className="offset-md-9 col-md-3">
                                    <input type="text" name="" onChange={_searchVideo} value={searchVideosList} className='form-control' placeholder='Search...' />
                                </div>
                            </div>
                            <div className="table-reaponsive">
                                <table className='table table-bordered'>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>
                                                {multiDeleteIdList.length ? 
                                                <i className='fa fa-trash-alt  text-danger' title="Click to delete selected video" style={{cursor:"pointer"}} onClick={multipleDelete}></i>
                                                   :
                                                <i className='fa fa-trash-alt ' title="Select 1 or more to delete"></i>
                                                }
                                            </th>

                                            <th>Thumbnail</th>
                                            <th>Title</th>
                                            <th>Industry</th>
                                            <th>Technology</th>
                                            <th>Use Case</th>
                                            <th>Product Category</th>
                                            <th>Clients</th>
                                            <th>URL</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                       {_tagList.length && videosList.map((e,i)=>{
                                        return(
                                            <tr>
                                                <td>{i+1}</td>
                                                <td> <input type="checkbox" onChange={()=>multipleDeleteId(e.id)}/> </td>
                                                <td><img className='thumbnaillist' src={`${videoPath}${e?.thumb_path}`} alt="" /> </td>
                                                <td>{e?.title}</td>
                                                <td>{e?.industry.map(item => _tagList.filter(tag => tag.id === item)[0].name).join(", ")}</td>
                                                <td>{e?.technology.map(item => _tagList.filter(tag => tag.id === item)[0].name).join(", ")}</td>
                                                <td>{e?.useCase.map(item => _tagList.filter(tag => tag.id === item)[0].name).join(", ")}</td>
                                                <td>{e?.productCategory.map(item => _tagList.filter(tag => tag.id === item)[0].name).join(", ")}</td>
                                                <td>{e?.clients.map(item => item).join(", ")}</td>
                                                <td>{e?.youtubeURL}</td>
                                                <td>
                                                    <Link className='text-primary' to={`/edit-video-uploader/${e.id}`}>
                                                        <i className='fa fa-edit pe-2'></i>
                                                    </Link>
                                                    | 
                                                    <span className='text-danger hoverBtn' onClick={()=>delete_video(e.id)}>
                                                        <i className='fa fa-trash-alt ps-2'></i>
                                                    </span>
                                                </td>
                                            </tr>
                                           )
                                         })}
                                      
                                        
                                    </tbody>
                                </table>
                                  
                                <div className="col-12">
                                    <ReactPaginate
                                      breakLabel="..."
                                      className="pagination"
                                      nextLabel={
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                      }
                                      onPageChange={handlePageClick}
                                      pageRangeDisplayed={5}
                                      pageCount={totalPages}
                                      previousLabel={
                                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                                      }
                                      renderOnZeroPageCount={null}
                                    />
                              </div>

                            </div>
                        </div>
                    </main>
                </>
      )

}

export default AllUploadedVideoList