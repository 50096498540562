import React, {useEffect, useState} from 'react'
import thumbnail from '../../assets/video/thumbnail/firesafety.jpg'
import ReactPlayer from 'react-player'
import videooverview from '../../assets/video/Simulanis-Team Overview.mp4'
import Modal from 'react-bootstrap/Modal';
import previewimg from '../../assets/video/thumbnail/preview.jpg'
import { videoPath } from "../../server"

const HomeBannner = (props) => {
const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [bannerImage, setBannerImage] = useState(previewimg);
  const [bannerVideo, setBannerVideo] = useState(videooverview);
  const [headerTitle, setHeaderTitle] = useState("Simulanis Team Overview")
  const [headerDesc, setHeaderDesc] = useState("Simulanis is an award-winning XR technology company dedicated to delivering excellence, specialising in immersive AR-VR applications since November 2013. By pioneering the application of XR technologies, we have revolutionized industrial training across various sectors.")

      useEffect(() => {
   
        if(props?._propsDataForHeader?.id){
            if(props?._propsDataForHeader?.video_path){
                setBannerVideo(`${videoPath}${props?._propsDataForHeader?.video_path}`);
                setHeaderTitle(props?._propsDataForHeader?.title);
                setHeaderDesc(props?._propsDataForHeader?.description);
                }
            
            if(props?._propsDataForHeader?.thumb_path){
                setBannerImage(`${videoPath}${props?._propsDataForHeader?.thumb_path}`);
                setHeaderTitle(props?._propsDataForHeader?.title);
                setHeaderDesc(props?._propsDataForHeader?.description);
            }
            
        }else{
            setBannerImage(previewimg);
            setBannerVideo(videooverview);
        }  


    },[props.changeBanner, props._propsDataForHeader]);


  const backgroundimg = {
    backgroundImage:`linear-gradient(to right, #000000f5, #000000b2, #ffffff9f), url(${bannerImage})`,
    backgroundPosition:"top",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <>
        <div className="progression-studios-slider-dashboard-image-background" style={backgroundimg}>
            <div className="progression-studios-slider-display-table">
                <div className="progression-studios-slider-vertical-align">
                    <div className="container" >
                        <div className="row align-tems-center">
                            <div className="col-md-7">
                                <div className="progression-studios-slider-dashboard-caption-width">
                                    <div className="progression-studios-slider-caption-align">
                                        <h2 className="light-fonts-pro"><a href="#">   {headerTitle}  </a></h2>
                                        <p className="progression-studios-slider-description light-fonts-pro"> {headerDesc} </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <a className="progression-studios-slider-play-btn afterglow" onClick={handleShow} href="#"><i className="fas fa-play"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <Modal className='videplayerpopup' centered={true} size='lg' show={show} onHide={handleClose}>
            <Modal.Body className='p-0'>
                <ReactPlayer
                    className='react-player fixed-bottom'
                    url= {bannerVideo}
                    width='100%'
                    height='100%'
                    controls = {true}
                    playing	= {true}
                />
            </Modal.Body>
        </Modal>
    </>
  )
}

export default HomeBannner