import React, { useEffect,useState } from 'react';
import HomeBannner from '../banner/HomeBannner'
import Filter from '../filter/Filter'
import VideoThumbnailCard from '../VideoThumbnailCard/VideoThumbnailCard';
import pharmaceutical from '../../assets/images/genres/pharmaceutical.png'
import automotive from '../../assets/images/genres/automotive.png'
import paintchemicals from '../../assets/images/genres/paintchemicals.png'
import automation from '../../assets/images/genres/automation.png'
import oilgas from '../../assets/images/genres/oilgas.png'
import energy from '../../assets/images/genres/energy.png'
import construction from '../../assets/images/genres/construction.png'
import renewable from '../../assets/images/genres/renewable.png'
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { getVideosList } from '../../utils/api-Request';

const MostViewed =()=> {
  const [mostViewVideo,setMostViewVideo] = useState([]);

  const _mostViewedVideo = async() =>{
    try {
      let data = {
        pageLimit:4,
        page:1,
        order: [["viewCount", "DESC"]],
        search : ""
      }
      let res = await getVideosList(data);
      setMostViewVideo(res?.data?.data)
    } catch (error) {
      console.log('');
    }
  }
  
  useEffect(()=>{
     _mostViewedVideo();
  },[])


  return (
    <>
        <section className="mostviewed bg-light">
            <div className="container">
              <h1>Most Viewed</h1>
              <div className="row">
                {mostViewVideo.map((e,i)=>{
                    return(
                        <div className="col-md-3"  key={i}>
                              <VideoThumbnailCard data={e} />
                        </div>
                      )
                  })}                 
              </div>
            </div>
        </section>
    </>
  )
}

export default MostViewed